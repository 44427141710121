import gsap from "gsap";

window.addEventListener('DOMContentLoaded', e => {
    animatedHeroBlock();
});

function animatedHeroBlock() {
    const instances = [...document.querySelectorAll('[data-animated-hero]')];
    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const instance = entry.target;
                const isAnimating = instance.getAttribute('data-animating') === 'true';

                if (!isAnimating) {
                    instance.setAttribute('data-animating', 'true'); // Set manual flag

                    let tl = gsap.timeline({
                        defaults: {
                            autoAlpha: 0,
                            ease: "power2",
                            duration: 1
                        },
                        onComplete: () => {
                            instance.removeAttribute('data-animating'); // Reset manual flag after animation is complete
                        }
                    });

                    const bg = instance.querySelector('[data-block-background]');
                    const elementsToAnimate = [
                        { selector: '[data-animate-title]', animationProps: { x: -30 }, delay: 0.5 },
                        { selector: '[data-animate-intro]', animationProps: { x: -30 }, delay: 0.7 },
                        { selector: '[data-animate-content]', animationProps: { x: -30 }, delay: 0.9 },
                        { selector: '[data-animate-buttons]', animationProps: { y: 30 }, delay: 1.3 },
                        { selector: '[data-animate-form]', animationProps: { x: 30, duration: 2 }, delay: 1.1 }
                    ];

                    if (bg) {
                        tl.from(bg, { scale: 1.2, duration: 2 })
                    }

                    elementsToAnimate.forEach(elementData => {
                        const element = instance.querySelector(elementData.selector);
                        if (element) {
                            tl.from(element, elementData.animationProps, elementData.delay);
                        }
                    });

                    const shouldRepeat = "repeat" in instance.dataset;

                    if (!shouldRepeat) {
                        observer.unobserve(instance);
                    }
                }
            }
        });
    }, observerOptions);

    instances.forEach(instance => {
        observer.observe(instance);
    });
}